import { navigate } from 'gatsby-link';
import React, { useEffect } from 'react';
import HoneyPotHidden from '../components/HoneyPotHidden';
import Routes from '../services/routes';

const endSubscriptionFormName = 'end-subscription-form';

const HiddenForms = () => {
  useEffect(() => {
    navigate(Routes.LANDING_PAGE);
  }, []);

  return (
    <>
      <form
        data-netlify="true"
        action="/"
        name={endSubscriptionFormName}
        netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value={endSubscriptionFormName} />
        <HoneyPotHidden />
        <input name="email" type="hidden" />
        <input name="name" type="hidden" />
        <input name="phone" type="hidden" />
        <input name="lagerplatser" type="hidden" />
        <input name="panbox" type="hidden" />
        <input name="message" type="hidden" />
      </form>
    </>
  );
};

export default HiddenForms;
